<template>
  <div>
    <div class="section detailtable" style="width: 90%; margin: 5rem auto 10rem auto">
      <div class="title-area">
        <div v-if="a" class="section-title bigger">
          <!--삶은감자튀김볶음탕면을 만들었어요~{{ recipeIndex }}-->
          {{a.title}}
        </div>
        <div class="writer-mini">
          <div class="writer-mini-thumbnail" >
            <img :src="this.imgUrlData"/>
          </div>
          <div v-if="displayName" class="writer-mini-name">{{ displayName }}</div>
        </div>
      </div>
      <div class="devider" style="margin: 0" />
      <div v-if="a" class="post-date">
        {{a.createdAt}}
      </div>
      <div class="post-content">
        <img src=""/>
        <div v-html='a.body' v-if="a">
        </div>
      </div>
      <div class="btn-area" style="margin-bottom:-3rem">
          <span hidden class="btn update"
          @click="modifyPosts(`${a._id}`)">수정</span>
          <span hidden class="btn write" style="margin-left: 1rem"
          @click="confirmDelete">삭제</span>
          <!-- 모달 컴포넌트 -->
    <Modal ref="modal" @confirm="deletePosts" @modalClosed="onModalClosed" />
        </div>
    <div class="devider" style="margin-bottom:2rem;" />
    <div class="comment-area">



      <!--댓글 영역-->
     <h1>댓글</h1>
     <div class="comment-list">
    <!-- 댓글 리스트 출력 -->

    <div v-for="comment in comments" :key="comment._id">
           <div class="comment-box">
            <span class="comment-img">
              <img :src="comment.userImg">
              </span>
        <span class="comment-author">{{ comment.nickName}}</span>
        <span class="comment-time">{{comment.createdAt}}</span>
        <span class="reply-btn" @click="toggleReplyForm(comment._id)">댓글</span>
        <span v-if="comment.author === userEmail">
          <unicon
          name="times-square"
          class="icon-square-style"
          width="15px"
          height="15px"
          @click="deleteComment(comment._id)"
          />
        </span>
        
      <div class="comment-content" v-html="formattedComment(comment.content)"></div> 



        <!-- 대댓글 목록 출력 (대댓글이 있을 때만 표시) -->
              <div class="reply-container" v-if="comment.replies && comment.replies.length > 0">
                <div v-for="reply in comment.replies" :key="reply._id" class="reply-box">
        <unicon
          name="corner-down-right"
          class="reply-arrow"
          width="30px"
          height="30px"
          />
                  <span class="reply-img">
              <img :src="reply.userImg">
              </span>
                  <span class="reply-author">{{ reply.nickName }}</span>
                  <span class="reply-time">{{ reply.createdAt }}</span>
                   <span v-if="reply.author === userEmail">
    <unicon
      name="times-square"
      class="icon-square-style"
      width="15px"
      height="15px"
      @click="deleteReply(comment._id, reply._id)"
    />
  </span>
                  <div class="reply-content">{{ reply.content }}</div>
                    <!-- 대댓글 삭제 버튼 (작성자만 삭제 가능하도록 설정) -->
 
                </div>
              </div>

              <!-- 대댓글 작성 버튼 및 작성란 -->
              
              <div v-if="showReplyForm[comment._id]" class="reply-content-box">
                <unicon
          name="corner-down-right"
          class="reply-arrow"
          width="30px"
          height="30px"
          />
                <textarea class="reply-text-area" v-model="newReplyContent[comment._id]" placeholder="댓글을 입력하세요"></textarea>
                <span class="reply-submit" @click="submitReply(comment._id)">작성</span>
              </div>
    </div> 
  </div>
  </div>
   
  <!-- 댓글 입력 필드 -->
  <div class="comment-content-box">
    <textarea class="comment-text-area" v-model="newComment" placeholder="댓글을 입력하세요"></textarea>
    <span class="comment-submit" @click="submitComment">작성</span>
  </div>
  </div> 
    </div>
        <!-- 에러 메시지 표시 -->
   
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { getAuth } from "firebase/auth";
import Modal from "../components/Modal.vue";

export default {
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      //recipeIndex: null,
      a: null,
      author: null,
      displayName: "",
      imgUrlData: "",
      userEmail: "",
      newComment: "", // 새로운 댓글을 위한 데이터
      comments: [], // 댓글 리스트
      replies: {}, // 각 댓글에 대응하는 대댓글 저장
      editContent: {}, // 댓글 수정 시 사용되는 데이터
      editReplyContent: {}, // 대댓글 수정 시 사용되는 데이터
      showReplyForm: {}, // 대댓글 작성창 표시 여부
      showEditForm: {}, // 댓글 수정창 표시 여부
      showReplyEditForm: {}, // 대댓글 수정창 표시 여부
      errorMessage: "", // 에러 메시지
      formattedText: "",

      postDeleted: false,

      newReplyContent: {}, // 각 댓글에 대한 새로운 대댓글 내용
      showReplyForm: {}, // 각 댓글에 대한 대댓글 작성란 표시 여부
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.fetchData();
    this.postAuthor();
    this.hiddenButton();
  },
  mounted() {
    this.getComments();
  },
  methods: {
    async fetchData() {
      try {
        const id = this.$route.params.id;
        const response = await axios.get(`/api/tables/${id}`);
        this.a = response.data;
        //    console.log(this.a);
      } catch (error) {
        console.error("에러났는데요", error);
      }
    },
    confirmDelete() {
      // 삭제 확인 모달창 열기 (삭제 모드)
      this.$refs.modal.openModal("게시물을 삭제하시겠습니까?", true);
    },
    async deletePosts() {
      const id = this.$route.params.id;
      try {
        const response = await axios.delete(`/api/tables/${id}`);
        this.$refs.modal.openModal("게시물 삭제 완료", false, true);
        this.postDeleted = true; // 삭제 완료 상태 설정
      } catch (error) {
        console.error("삭제 실패:", error);
      }
    },
    onModalClosed() {
      // 삭제 완료 후 모달이 닫혔을 때만 라우팅
      if (this.postDeleted) {
        this.$router.push("/tables");
      }
    },
    async modifyPosts(path) {
      this.$router.push(`/tables/modify/${path}`);
    },
    //게시물 작성자 가져오는거
    async postAuthor() {
      try {
        const id = this.$route.params.id;
        const response = await axios.post(`/api/tables/${id}`);
        this.author = response.data;
        this.displayName = this.author.displayName;
        this.imgUrlData = response.data.userImg;
      } catch (error) {
        console.error("Error fetching author:", error);
      }
    },
    //로그인한 본인의 게시물이면 수정이랑 삭제버튼 보이게
    async hiddenButton() {
      if (this.getUser) {
        try {
          const id = this.$route.params.id;
          this.author = await axios.post(`/api/tables/${id}`);
          this.loginName = this.getUser.email;

          if (this.author.data.email == this.loginName) {
            document.querySelector(".btn.update").hidden = false;
            document.querySelector(".btn.write").hidden = false;
          }
        } catch (error) {
          console.error("Error fetching author:", error);
        }
      }
    },

    // 댓글 작성 함수
    async submitComment() {
      if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      } else {
        const postId = this.$route.params.id; // 현재 게시물 ID 추출
        const author = this.getUser.email; // 실제 로그인 정보를 사용해야 함
        const content = this.newComment; // 새로운 댓글 내용

        try {
          const response = await axios.post(`/api/comment/${postId}/comments`, {
            author,
            content,
          });
          this.comments = response.data; // 댓글 리스트에 추가
          //console.log(`여기확인`, this.comments);
          this.newComment = ""; // 댓글 입력 필드 초기화
          this.$refs.modal.openModal("댓글 작성완료");
          this.getComments();
        } catch (error) {
          this.errorMessage = "댓글 작성 실패: " + error.response.data.error; // 에러 메시지 표시
        }
      }
    },

    // 대댓글 작성란 토글
    toggleReplyForm(commentId) {
      this.$set(this.showReplyForm, commentId, !this.showReplyForm[commentId]);
    },
    // 대댓글 작성
    async submitReply(commentId) {
       if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      }else{
      const postId = this.$route.params.id; // 게시물 ID 추출
      const content = this.newReplyContent[commentId]; // 대댓글 내용
      const author = this.getUser.email; // 작성자 정보

      if (!content) return; // 대댓글 내용이 없으면 함수 종료

      try {
        // 대댓글 작성 요청을 서버로 보냄
        const response = await axios.post(
          `/api/comment/${postId}/comments/${commentId}/reply`,
          { author, content }
        );
        console.log(response.data);
        // 서버로부터 업데이트된 대댓글 목록을 받아와서 현재 댓글의 대댓글로 설정
        const updatedReplies = response.data;
        const commentIndex = this.comments.findIndex(
          (comment) => comment._id === commentId
        );
        if (commentIndex !== -1) {
          this.comments[commentIndex].replies = updatedReplies;
        }

        // 대댓글 입력 필드 초기화 및 대댓글 작성란 닫기
        this.newReplyContent[commentId] = "";
        this.showReplyForm[commentId] = false;
        this.$refs.modal.openModal("댓글 작성완료");
        
      } catch (error) {
        console.error("대댓글 작성 실패:", error);
      }
      }
    },
    // 대댓글 삭제
  async deleteReply(commentId, replyId) {
    const postId = this.$route.params.id;

    try {
      // 서버에 대댓글 삭제 요청
      const response = await axios.delete(
        `/api/comment/${postId}/comments/${commentId}/reply/${replyId}`
      );

      // 서버로부터 갱신된 대댓글 목록을 받아와 댓글에 반영
      const updatedReplies = response.data.replies;
      const commentIndex = this.comments.findIndex(comment => comment._id === commentId);
      if (commentIndex !== -1) {
        this.comments[commentIndex].replies = updatedReplies;
      }
      this.$refs.modal.openModal("댓글 삭제완료");
    } catch (error) {
      console.error("대댓글 삭제 실패:", error);
    }
  },
    //댓글 불러오는 함수
    async getComments() {
      const postId = this.$route.params.id;

      //  console.log(`여기`, this.getUser);
      if (this.getUser == null) {
        const response2 = await axios.get(`/api/comment/${postId}/comments`);
        this.comments = response2.data.comments;
      } else {
        const author = this.getUser.email;
        this.userEmail = this.getUser.email;
        try {
          const response = await axios.get(`/api/comment/${postId}/comments`, {
            params: {
              author,
            },
          });
          this.comments = response.data.comments; // 서버에서 불러온 댓글 목록 설정
          //this.formattedText = this.comments.content.replace(/\n/g, "<br>");
        } catch (error) {
          this.errorMessage =
            "댓글 목록 불러오기 실패: " + error.response.data.error;
        }
      }
    },
    // 댓글 삭제 함수
    async deleteComment(commentId) {
      const postId = this.$route.params.id; // 현재 게시물 ID 추출

      try {
        await axios.delete(`/api/comment/${postId}/comments/${commentId}`); // 댓글 삭제 요청
        this.$refs.modal.openModal("댓글 삭제완료");
        this.getComments();
      } catch (error) {
        this.errorMessage = "댓글 삭제 실패: " + error.response.data.error; // 에러 메시지 표시
      }
    },
    formattedComment(content) {
      if (content) {
        // \n을 <br>로 변환하여 반환
        return content.replace(/\n/g, "<br>");
      }
      return content;
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/detail-table.css");
@import url("@/assets/styles/comment.css");
</style>
