<template>
  <div class="section">
    <div class="section-recipe-title addfont2" style="margin-bottom: 9rem">
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      개인정보 수정
    </div>
    <div class="profile-container" style="margin-left: 10rem">
      <div class="img-profile raduis">
        <div class="imgBox">
          <span v-if="!(getUser?.photoURL || this.imageUrl)">
            사진을 등록해보세요.</span
          >
          <img v-else :src="this.imageUrl || getUser?.photoURL" />
        </div>
        <span class="btn submit1" @click="triggerFileInput">이미지 업로드</span>
      </div>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="handleFileChange"
        accept=".jpg, .jpeg, .png"
      />
      <div class="info-container">
        <div class="profile-item">
          <input v-show="modName" v-model="newName" />
          <a v-show="!modName"> {{ getUser?.displayName }}</a>
          <span class="btn-edit" @click="modNameToggle"
            ><unicon
              name="edit"
              width="20"
              height="20"
              fill="rgb(71, 113, 93)"
            ></unicon
          ></span>
        </div>
        <div class="profile-item user-name">ID: {{ getUser?.email }}</div>
        <!--<div class="profile-item user-name">
          PW:
          <input v-show="modPW" @input="validateInput" v-model="newPW" />
          <a v-show="!modPW">재설정하기</a
          ><span class="btn-edit pw" @click="modPWToggle"
            ><unicon
              name="edit"
              width="15"
              height="15"
              fill="rgb(71, 113, 93)"
            ></unicon
          ></span>
        </div>-->

        <form @submit.prevent="updatePassword">
          <div class="profile-item user-name">
            <label style="padding-right: 1rem" for="current-password"
              >현재 비밀번호</label
            >
            <input type="text" v-model="currentPassword" required />
          </div>
          <div class="profile-item user-name" style="display: inline-block">
            <label style="padding-right: 1.8rem" for="new-password"
              >새 비밀번호</label
            >
            <input
              type="text"
              v-model="newPassword"
              @input="checkPW"
              required
            />
          </div>
          <!--<button type="submit">비밀번호 변경</button>-->
          <span class="caution" style="margin-left: 1rem" v-show="incorrectPW"
            >동일한 비밀번호를 사용할 수 없습니다.</span
          >
        </form>
        <div class="profile-item user-name">
          <textarea
            class="introduction-box"
            v-show="modIntroduction"
            v-model="newBio"
          />
          <a v-show="!modIntroduction" v-html="formattedText"></a>
          <span class="btn-edit" @click="modIntroductionToggle"
            ><unicon
              name="edit"
              width="20"
              height="20"
              fill="rgb(71, 113, 93)"
            ></unicon
          ></span>
        </div>

        <!-- 성공 및 에러 메시지 -->
      </div>
    </div>

    <div class="btn-area">
      <span class="btn submit" style="margin-right: 5px" @click="confirmDelete"
        >회원 탈퇴</span
      >
      <span class="btn submit" @click="updateProfile1">저장</span>
      <!-- 모달 컴포넌트 -->
      <Modal
        ref="modal"
        @confirm="deleteUserData"
        @modalClosed="onModalClosed"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  updateProfile,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { storage, auth } from "@/main.js";
import axios from "axios";
import Modal from "../components/Modal.vue";

export default {
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      modName: false,
      modPW: false,
      modIntroduction: false,

      imageUrl: null, // 화면에 바로 띄울 거

      newName: "",
      newPW: "",
      newBio: "",
      newPic: null,

      currentPassword: "", // 현재 비밀번호
      newPassword: "", // 새 비밀번호
      message: "", // 상태 메시지
      messageType: "", // 성공 또는 오류 메시지

      incorrectPW: false,
      nowCheck: false,

      newSelfIntroduction: "",
      formattedText: "",
      redirectPath: "",

      userdelete: false,
      usermail: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.modifySelfIntroduction();
  },

  methods: {
    async updateBio() {
      try {
        this.userMail = this.getUser.email;

        const response = await axios.put(`/api/mypage/info`, {
          email: this.userMail,
          selfIntroduction: this.newBio,
        });
      //  console.log(`한줄소개 수정됨`, response.data);
      } catch (error) {
        console.error(`한줄소개 수정안됨`, error);
      }
    },
    async updateNickname() {
      try {
        this.userMail = this.getUser.email;

        const response = await axios.put(`/api/mypage/info/newname`, {
          email: this.userMail,
          newName: this.newName,
        });
      //  console.log(`넥네임 수정됨`, response.data);
      } catch (error) {
        console.error(`닉네임 수정안됨`, error);
      }
    },
    async updateProfileImg(){
      this.userMail = this.getUser.email;
      try{
      const response = await axios.put(`/api/mypage/info/newprofileimg`, {
          email: this.userMail,
          newPic: this.newPic
        });
    //    console.log(`이미지 수정됨`, response.data);
        }catch(error){
          console.error(`이미지 수정안됨`,error);
        }
    },

    async modifySelfIntroduction() {
      //this.selfIntroduction = "hello";
      //console.log(this.selfIntroduction);

      this.userMail = this.getUser.email;

      const response = await axios.post(`/api/mypage/info`, {
        email: this.userMail,
      });
      const responseData = response.data;
     // console.log(response);
      //console.log(`받은값: `, responseData);
      this.newSelfIntroduction = responseData;
      this.formattedText = this.newSelfIntroduction.replace(/\n/g, "<br>");

      //console.log(this.selfIntroduction);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0]; // 선택된 파일을 가져옴
      if (!file) {
        return;
      }
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // 이미지를 화면에 바로 보여줌 (미리보기)
        };
        reader.readAsDataURL(file);

        const uniqueFileName = `${Date.now()}_${file.name}`; // 파일명을 유니크하게 생성 (타임스탬프 + 파일 이름)
        const storageRef = ref(storage, `profileImage/${uniqueFileName}`); // Firebase Storage 경로

        // 파일을 Firebase Storage에 업로드
        const snapshot = await uploadBytes(storageRef, file);

        // 업로드된 파일의 다운로드 URL을 가져옴
        const url = await getDownloadURL(snapshot.ref);

        this.imageUrl = url; // Firebase에서 다운로드한 URL을 imageUrl에 저장하여 즉시 반영
        this.newPic = url; // 새로운 이미지 URL을 newPic에 저장 (프로필 업데이트를 위한 준비)
      }
    },
    modNameToggle() {
      this.modName = !this.modName;
    },
    modPWToggle() {
      this.modPW = !this.modPW;
    },
    modIntroductionToggle() {
      this.modIntroduction = !this.modIntroduction;
    },

    validateInput(event) {
      // 입력값에서 숫자, 영어, 특수문자만 남기기
      const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>_\-+=\\/'\[\];~`]+$/;
      const input = event.target.value;

      // 정규식에 맞는 값만 필터링
      if (!regex.test(input)) {
        this.inputValue = input.replace(
          /[^a-zA-Z0-9!@#$%^&*(),.?":{}|<>_\-+=\\/'\[\];~`]+/g,
          ""
        );
      }
    },

    async updateProfile1() {
      await this.verifyPassword();
   //   console.log(`this.nowCheck`, this.nowCheck);
   //   console.log(`this.incorrectPW`, this.incorrectPW);
      const auth = getAuth();
      const user = auth.currentUser; //현재 로그인된 사용자 가져오기

      if (user) {
        try {
          // 모든 값이 입력되지 않은 경우
          if (
            !this.newName &&
            !this.newPic &&
            !this.newBio &&
            !this.currentPassword
          ) {
            this.$refs.modal.openModal("변경된 내용이 없습니다");
          }

          // 새 이름 또는 새 이미지 또는 새 소개가 있고, 비밀번호가 일치하며 재인증 성공한 경우
          else if (
            (this.newName || this.newPic || this.newBio) &&
            this.incorrectPW === false &&
            this.nowCheck === true
          ) {
         //   console.log(`일단여기확인이요`);
            await updateProfile(user, {
              displayName: this.newName || user.displayName,
              photoURL: this.newPic || user.photoURL,
            });
            this.updateProfileImg();
            if (this.newBio) {
              this.updateBio(); // 새 소개 업데이트
            }
            if (this.newName) {
              this.updateNickname();
            }
            this.updatePassword1();
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";

          //  console.log("Profile updated");
          }

          // 새 이름만 변경하는 경우 (비밀번호 입력하지 않음)
          else if (
            this.newName &&
            !this.newPic &&
            !this.newBio &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            await updateProfile(user, {
              displayName: this.newName,
            });
            if (this.newName) {
              this.updateNickname();
            }
          //  console.log(`새 이름만 변경`);
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          //  console.log(this.redirectPath);
          }

          // 새 이미지만 변경하는 경우 (비밀번호 입력하지 않음)
          else if (
            this.newPic &&
            !this.newName &&
            !this.newBio &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            await updateProfile(user, {
              photoURL: this.newPic,
            });
            this.updateProfileImg();
          //  console.log(`새 이미지만 변경`);
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }

          // 새 소개만 변경하는 경우 (비밀번호 입력하지 않음)
          else if (
            this.newBio &&
            !this.newName &&
            !this.newPic &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            this.updateBio();
          //  console.log(`새 소개만 변경`);
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }

          // 새 이름과 새 소개만 변경한 경우
          else if (
            this.newName &&
            this.newBio &&
            !this.newPic &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            await updateProfile(user, {
              displayName: this.newName,
            });
            if (this.newName) {
              this.updateNickname();
            }
            this.updateBio(); // 새 소개 업데이트
          //  console.log("새 이름과 새 소개만 변경됨");
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }
          // 비밀번호만 변경하는 경우
          else if (
            !this.newName &&
            !this.newPic &&
            !this.newBio &&
            this.currentPassword &&
            this.updatePassword
          ) {
            this.updatePassword1(); // 비밀번호 업데이트
          //  console.log("비밀번호만 변경됨");
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          } else if (
            !this.newName &&
            !this.newPic &&
            !this.newBio &&
            this.currentPassword &&
            this.updatePassword &&
            (this.incorrectPW === true || this.nowCheck === false)
          ) {
            this.$refs.modal.openModal("비밀번호 조건이 맞지 않습니다");
          }
          // 새 이미지와 새 소개만 변경한 경우
          else if (
            this.newPic &&
            this.newBio &&
            !this.newName &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            await updateProfile(user, {
              photoURL: this.newPic,
            });
            this.updateProfileImg();
            this.updateBio(); // 새 소개 업데이트
         //   console.log("새 이미지와 새 소개만 변경됨");
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }

          // 새 비밀번호와 새 소개만 변경한 경우
          else if (
            this.newBio &&
            this.currentPassword &&
            this.updatePassword &&
            !this.newName &&
            !this.newPic
          ) {
            this.updateBio(); // 새 소개 업데이트
            this.updatePassword1(); // 비밀번호 업데이트
          //  console.log("새 비밀번호와 새 소개만 변경됨");
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }

          // 새 이름과 새 이미지가 있고, 비밀번호는 입력하지 않은 경우
          else if (
            this.newName &&
            this.newPic &&
            !this.currentPassword &&
            !this.updatePassword
          ) {
            await updateProfile(user, {
              displayName: this.newName,
              photoURL: this.newPic,
            });
            if (this.newName) {
              this.updateNickname();
            }
            this.updateProfileImg();
           // console.log("새 이름과 새 이미지가 모두 변경됨");
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }

          // 새 이름 또는 새 이미지 또는 새 소개가 있고, 동일한 비밀번호 사용 시 경고
          else if (
            (this.newName || this.newPic || this.newBio) &&
            this.incorrectPW === true &&
            this.nowCheck === true
          ) {
            this.$refs.modal.openModal("동일한 비밀번호를 사용할 수 없습니다");
          }

          // 새 이름 또는 새 이미지 또는 새 소개가 있지만 비밀번호가 맞지 않는 경우
          else if (
            (this.newName || this.newPic || this.newBio) &&
            (this.incorrectPW === true || this.nowCheck === false)
          ) {
            this.$refs.modal.openModal("비밀번호 조건이 맞지 않습니다");
          }

          // 새 이름 또는 새 이미지 또는 새 소개가 없고, 비밀번호 조건이 맞는 경우
          else if (
            !this.newName &&
            !this.newPic &&
            !this.newBio &&
            this.incorrectPW === false &&
            this.nowCheck === true
          ) {
            if (this.newName) {
              this.updateNickname();
            }
            this.updateProfileImg();
            this.updatePassword1(); // 비밀번호만 업데이트
            this.$refs.modal.openModal("정보 수정 완료");
            this.redirectPath = this.$route.query.redirect || "/mypage/mypost";
          }
        } catch (error) {
          console.log(error);
          this.$refs.modal.openModal("정보 수정에 실패했습니다");
        }
      } else {
        console.error("No user is logged in");
      }
    },
    async updatePassword1() {
      //console.log(`안녕하세요`);
      const auth = getAuth();
      //console.log(`auth값ㅇㅡㄴ111111111:`, auth);
      const user = auth.currentUser; //현재 로그인된 사용자 가져오기
      //console.log(`user값은11111111:`, user);

      //console.log(`실행되나?`);
      if (user) {
        try {
          const credential = EmailAuthProvider.credential(
            user.email,
            this.currentPassword
          );
          //console.log(`머라고나오지:`, credential);
          //////////////////////여기문제임 ~
          await reauthenticateWithCredential(user, credential);

          //console.log("인증 성공했나");

          if (this.newPassword != this.currentPassword) {
            await updatePassword(user, this.newPassword);
          }
          // 비밀번호 업데이트
          //console.log(`완료됐나?`);
        } catch (error) {
          console.error("에러 발생:", error); // 에러 메시지 출력
        }
      } else {
        this.message = "로그인이 필요합니다.";
        this.messageType = "error";
      }
    },
    checkPW() {
      if (this.currentPassword != this.newPassword) {
        this.incorrectPW = false;
      } else {
        this.incorrectPW = true;
      }
    },
    async verifyPassword() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const credential = EmailAuthProvider.credential(
          user.email,
          this.currentPassword
        );
      //  console.log(credential);
        try {
          await reauthenticateWithCredential(user, credential);
          this.nowCheck = true; // 비밀번호 일치
        //  console.log("재인증 성공!");
        //  console.log(this.nowCheck);
        } catch (error) {
          this.nowCheck = false; // 비밀번호 불일치
          console.error(
            "재인증 실패(비밀번호를 입력하지않으면 이 에러가 뜨기도 함.):",
            error
          );
        }
      } else {
       // console.log("로그인된 사용자가 없습니다.");
      }
    },
    onModalClosed() {
      // 삭제 완료 후 모달이 닫혔을 때만 라우팅
      if (this.userdelete) {
        // 탈퇴 완료 후 index 페이지로 이동
       // console.log("회원 탈퇴 후, index 페이지로 이동합니다."); // 확인용 로그
        this.$router
          .push(`/`)
          .then(() => {
            // 페이지 이동 후 스크롤 맨 위로
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      } else {
        if (this.$route.fullPath !== this.redirectPath) {
          this.$router
            .push(`${this.redirectPath}`)
            .then(() => {
              // 페이지 이동 후 스크롤 맨 위로
              window.scrollTo(0, 0);
            })
            .catch((err) => {
              if (err.name !== "NavigationDuplicated") {
                throw err;
              }
            });
        }
      }
    },
    async deleteUserData() {
     // console.log("deleteUserData 함수가 호출되었습니다."); // 로그 추가
      try {
        //console.log(`참조되는지확인`, this.$refs.modal);
        // Firebase 인증 객체에서 현재 로그인된 사용자 가져오기
        const user = auth.currentUser;

        // 로그인된 사용자가 없을 경우 에러 처리
        if (!user) {
          throw new Error("사용자가 로그인되어 있지 않습니다.");
        }

        // Firebase에서 현재 로그인된 사용자의 ID 토큰 가져오기
        const idToken = await user.getIdToken(true);

        // 서버로 사용자 삭제 요청을 보낼 때, 토큰을 함께 전송
        await axios.post("/api/mypage/delete-user", { idToken: idToken });
       // console.log("openModal 호출 전");

        // 탈퇴가 성공했을 때 완료 모달 표시
        this.$refs.modal.openModal("회원 탈퇴가 완료되었습니다.", false, true);
       // console.log("openModal 호출 후");
        await auth.signOut();
        this.userdelete = true;
      } catch (error) {
        console.error("회원 탈퇴 실패:", error);
        alert("회원 탈퇴에 실패했습니다. 다시 시도해 주세요.");
      }
    },
    confirmDelete() {
      // 삭제 확인 모달창 열기 (삭제 모드)
      this.$refs.modal.openModal("회원을 탈퇴하시겠습니까?", true);
      //console.log("confirmDelete 함수가 호출되었습니다."); // 로그 추가
    },
  },
};
</script>

<style>
@import url("@/assets/styles/modifyInfo.css");
</style>