//이미지 추가 핸들러를 직접 구현함;;?? 뭔가됨 일단 모듈로 뺐음

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../src/main";

// 이미지 선택 및 업로드 함수
export async function selectLocalImage(editorRef) {
  console.log("selectLocalImage 함수가 호출되었습니다.");  // 함수 호출 확인

  // 에디터 초기화: 내용을 비움
  //editorRef.quill.setContents([]);  // 에디터의 내용을 초기화 (빈 상태로 만듦)
  //console.log("에디터 내용 초기화됨.");  // 에디터 초기화 로그

  let input = '';  // input 요소 초기화
  input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.value = '';  // 이전 파일 선택 값을 초기화
  input.click();  // 파일 선택 창을 열기
  console.log("파일 선택 창 열림.");  // 파일 선택 창 로그

  // 파일이 선택되었을 때 실행되는 이벤트 핸들러
  input.onchange = async () => {
    const file = input.files[0];  // 선택된 파일을 가져옴
    if (!file) {
      console.log("파일이 선택되지 않음.");  // 파일 선택 안 됐을 때 로그
      return;
    }
    
    console.log("선택된 파일:", file);  // 선택된 파일 로그

    const uniqueFileName = `${Date.now()}_${file.name}`;  // 파일명을 유니크하게 생성 (타임스탬프 + 파일 이름)
    console.log("유니크 파일 이름 생성:", uniqueFileName);  // 파일 이름 로그
    
    // Firebase Storage에 저장할 참조 경로를 생성
    const storageRef = ref(storage, `postsImage/${uniqueFileName}`);

    // 파일을 Firebase Storage에 업로드
    const snapshot = await uploadBytes(storageRef, file);
    console.log("파일이 Firebase Storage에 업로드됨:", snapshot);  // 파일 업로드 로그
    
    // 업로드된 파일의 다운로드 URL을 가져옴
    const url = await getDownloadURL(snapshot.ref);
    console.log("업로드된 파일의 URL:", url);  // 파일 URL 로그

    // 에디터의 현재 커서 위치(range) 가져오기
    const range = editorRef.quill.getSelection();
    console.log("현재 에디터 커서 위치:", range);  // 커서 위치 로그

    // 에디터의 커서 위치에 이미지를 삽입 (URL로 삽입)
    editorRef.quill.insertEmbed(range.index, 'image', url);
    console.log("에디터에 이미지 삽입됨.");  // 이미지 삽입 로그
  };
}

