import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import InfiniteLoading  from "vue-infinite-loading";

import {
  uniKeyholeCircle,
  uniUserCircle,
  uniAngleDoubleDown,
  uniAt,
  uniKeyholeCircleSolid,
  uniEdit,
  uniPlus,
  uniMinus,
  uniArrowCircleDown,
  uniRestaurant,
  uniSearch,
  uniBookmark,
  uniBookmarkSolid,
  uniThumbsUp,
  uniFavorite,
  uniTimesSquare,
  uniArrowCircleUp,
  uniArrowUp,
  uniBars,
  uniMessage,
  uniEnvelope,
  uniLink,
  uniComment,
  uniCornerDownRight
} from "vue-unicons/dist/icons";

Unicon.add([
  uniKeyholeCircle,
  uniUserCircle,
  uniAngleDoubleDown,
  uniAt,
  uniKeyholeCircleSolid,
  uniEdit,
  uniPlus,
  uniMinus,
  uniArrowCircleDown,
  uniRestaurant,
  uniSearch,
  uniBookmark,
  uniBookmarkSolid,
  uniThumbsUp,
  uniFavorite,
  uniTimesSquare,
  uniArrowCircleUp,
  uniArrowUp,
  uniBars,
  uniMessage,
  uniEnvelope,
  uniLink,
  uniComment,
  uniCornerDownRight
]);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebase from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCXWVWDnBSJHQw1RBxXPQWsb0jfheuAcWQ",
  authDomain: "recipe-vue-ffe87.firebaseapp.com",
  projectId: "recipe-vue-ffe87",
  storageBucket: "recipe-vue-ffe87.appspot.com",
  messagingSenderId: "627722741546",
  appId: "1:627722741546:web:b9bdd725d01cfc30b02053",
};

// Firebase 초기화
const firebaseApp = initializeApp(firebaseConfig);

// Firebase 서비스 초기화 (필요한 경우)
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // 로그인된 사용자 정보를 Vuex 상태에 저장
    store.dispatch("setUser", user);
  } else {
    // 로그아웃 시 Vuex 상태를 초기화
    store.dispatch("clearUser");
  }
});

// Firebase 객체를 export하여 다른 Vue 컴포넌트에서도 사용 가능하도록 설정
export { auth, db, storage, firebase };

Vue.use(Unicon);
Vue.component('infinite-loading', InfiniteLoading);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
