<template>
  <div class="section signUp">
    <div class="login-area">
      <div class="section-title" style="text-align: center; margin: 0 0 30px 0">
        회원가입
      </div>
      <div class="input-area" @keypress.enter.prevent="signUp">
        <div class="input-row">
          <unicon
            name="at"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          />
          <input
            v-model="email"
            placeholder="이메일을 입력하세요"
            @input="checkDuplicateEmail"
          />
        </div>
        <span class="caution2" v-show="emailErrorMessage">{{
          emailErrorMessage
        }}</span>
        <div class="input-row">
          <unicon
            name="user-circle"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          />
          <input v-model="nickname" placeholder="닉네임을 입력하세요" />
        </div>
        <div class="input-row">
          <unicon
            name="keyhole-circle"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          /><input
            v-model="password"
            placeholder="비밀번호(6자리 이상)를 입력하세요"
            type="password"
          />
        </div>
        <div class="input-row" style="margin: 15px 0 5px 0">
          <unicon
            name="keyhole-circle"
            icon-style="solid"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          /><input
            v-model="pw2"
            placeholder="비밀번호를 재입력하세요"
            type="password"
            @input="checkPW"
          />
        </div>
        <span class="caution" v-show="incorrectPW"
          >비밀번호가 일치하지 않습니다.</span
        >
      </div>

      <span class="btn submit" @click="signUp">가입하기</span>
    </div>
     <Modal ref="modal"  @modalClosed="onModalClosed"/>
  </div>
</template>

<script>
import {
  getAuth,
  fetchSignInMethodsForEmail, //이메일 중복여부 확인하려고
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import axios from "axios";
import Modal from '../components/Modal.vue'; 

export default {
  components: {
    Modal,
  },
  name: "signup",
  data() {
    return {
      email: "",  
      password: "",
      pw2: "",
      nickname: "",
      incorrectPW: false,
      isDuplicateEmail: false,
      debounceTimer: null, // 디바운스 타이머 추가
      emailErrorMessage: "", // 이메일 형식 오류 메시지 추가
      userIntroduction: null,
      isSignUpComplete: false, // 회원가입 완료 플래그
    };
  },
  methods: {
    checkDuplicateEmail() {
      clearTimeout(this.debounceTimer);

      // 새 디바운스 타이머 설정 (500ms 후 이메일 중복 및 형식 체크 실행)
      this.debounceTimer = setTimeout(async () => {
        if (this.email.trim() === "") {
          this.isDuplicateEmail = false;
          this.emailErrorMessage = "이메일을 입력하세요.";
          return;
        }

        // 이메일 형식 검증
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.email)) {
          this.emailErrorMessage = "올바른 이메일 형식을 입력하세요.";
          this.isDuplicateEmail = false;
          return;
        }

        // 이메일 형식이 올바르면 Firebase로 중복 체크
        try {
          const auth = getAuth();
          const trimmedEmail = this.email.trim().toLowerCase();
          let signInMethods = [];

          try {
            signInMethods = await fetchSignInMethodsForEmail(auth, this.email);
        //    console.log("signInMethods값:", signInMethods);
          } catch (error) {
            console.error("Error fetching sign-in methods:", error);
          }
          // 디버깅용 로그
          //console.log("auth", auth);
         // console.log("공백제거소문자:", trimmedEmail);
         // console.log("아니이거왜안되는데", this.email);
          // 로그인 방법이 하나라도 존재하면 중복된 이메일로 간주
          this.isDuplicateEmail = signInMethods.length > 0;

          // 상태에 따른 오류 메시지 설정
          this.emailErrorMessage = this.isDuplicateEmail
            ? "중복된 이메일입니다."
            : "";
        } catch (error) {
          console.error("Error checking duplicate email:", error);
          this.isDuplicateEmail = false;
          this.emailErrorMessage = "중복된 이메일을 확인할 수 없습니다.";
        }
      }, 500); // 500ms 동안 입력이 없으면 실행
    },
    checkPW() {
      if (this.pw2 === this.password) {
        this.incorrectPW = false;
      } else {
        this.incorrectPW = true;
      }
    },
    async signUp() {
      if (!this.incorrectPW) {
        //console.log(this.isDuplicateEmail, `correct->`, this.incorrectPW);
        if (!(this.email.trim().length > 0)) {
          this.$refs.modal.openModal("이메일을 입력해주세요");
        } else if (this.password == null || this.pw2 == null) {
          this.$refs.modal.openModal("비밀번호를 입력해주세요");
        } else if (this.isDuplicateEmail == false && this.incorrectPW == true) {
          this.$refs.modal.openModal("잘못된 이메일입니다");
        } else if (
          this.isDuplicateEmail == false &&
          this.incorrectPW == false &&
          !(this.password.trim().length > 5)
        ) {
          this.$refs.modal.openModal("잘못된 정보입니다");
        } else if (this.isDuplicateEmail == true && this.incorrectPW == true) {
          this.$refs.modal.openModal("잘못된 정보입니다");
        } else if (
          this.isDuplicateEmail == false &&
          (this.password == "" || this.pw2 == "") &&
          this.incorrectPW == false
        ) {
          this.$refs.modal.openModal("비밀번호를 입력해주세요");
        } else if (
          this.isDuplicateEmail == true &&
          !(this.password.trim().length > 5)
        ) {
          this.$refs.modal.openModal("잘못된 이메일입니다");
        } else if (
          this.isDuplicateEmail == false &&
          (this.password == "" || this.pw2 == "")
        ) {
          this.$refs.modal.openModal("비밀번호를 입력해주세요");
        } else if (this.isDuplicateEmail == true && this.incorrectPw == false) {
          this.$refs.modal.openModal("잘못된 이메일입니다");
        } else if (!(this.password.trim().length > 5)) {
          this.$refs.modal.openModal("6자리 이상 비밀번호를 입력해주세요");
        } else if (
          this.isDuplicateEmail == true &&
          (this.password == null || this.pw2 == null)
        ) {
          this.$refs.modal.openModal("비밀번호를 입력해주세요");
        } else {
          const auth = getAuth(); // Firebase auth 인스턴스 생성
          try {
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              this.email,
              this.password
            );
            const user = userCredential.user;
            //console.log(`비번1:`,this.password,`비번2:`,this.pw2);
            await updateProfile(user, {
              displayName: this.nickname,
            });
            await this.saveUserData();
            this.isSignUpComplete = true;
            this.$refs.modal.openModal("회원가입 완료",false);
          } catch (err) {
            this.$refs.modal.openModal(`에러:`,err.message);
          }
        }
      } else if (
        this.incorrectPW == true &&
        this.isDuplicateEmail == true &&
        this.email == ""
      ) {
        this.$refs.modal.openModal("이메일을 입력해주세요");
      } else if (
        this.incorrectPW == true &&
        this.isDuplicateEmail == false &&
        this.email == ""
      ) {
        this.$refs.modal.openModal("이메일을 입력해주세요");
      } else if (
        this.isDuplicateEmail == true &&
        this.incorrectPW == false &&
        this.email == ""
      ) {
        this.$refs.modal.openModal("이메일을 입력해주세요");
      } else {
        this.$refs.modal.openModal("잘못된 정보입니다");
      }
    },
    async saveUserData() {
      try {
        const response = await axios.post("/api/signup", {
          email: this.email,
          userIntroduction: this.userIntroduction,
          nickname: this.nickname,
        });
        //console.log("이메일db저장완료", response.data);
      } catch (error) {
        console.error(`이메일db저장실패`, error);
      }
    },
    onModalClosed() {
        if (this.isSignUpComplete) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/signin-up.css");
</style>
