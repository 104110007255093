import { render, staticRenderFns } from "./DetailRecipe.vue?vue&type=template&id=697e5059&scoped=true"
import script from "./DetailRecipe.vue?vue&type=script&lang=js"
export * from "./DetailRecipe.vue?vue&type=script&lang=js"
import style0 from "./DetailRecipe.vue?vue&type=style&index=0&id=697e5059&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697e5059",
  null
  
)

export default component.exports