<template>
  <div class="section">
    <div class="section-recipe-title addfont2" style="margin-bottom: 9rem">
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      식재료 보관 팁
    </div>
    <div class="tip-icon-container">
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-steak-135628.png?alt=media&token=413ce569-8e48-440a-a952-13be60d9345b"
          @click="sendIconValue('육류')"
        />
        <span>육류</span>
      </div>

      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-carrot-135687.png?alt=media&token=77d3028b-5e46-4288-b1de-7585da090d57"
          @click="sendIconValue('채소')"
        />
        <span>채소</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-grapes-135542.png?alt=media&token=2bdff2ae-82c3-4c84-8e1d-098158d57b21"
          @click="sendIconValue('과일')"
        />
        <span>과일</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-beans-135708.png?alt=media&token=60779210-6e7b-454e-bc61-342cee26a2d5"
          @click="sendIconValue('콩류')"
        />
        <span>콩류</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-shrimp-135675.png?alt=media&token=c82924c5-7cc5-4660-8270-0d6aaa0bba1d"
          @click="sendIconValue('갑각류')"
        />
        <span>갑각류</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-grain-135712.png?alt=media&token=56bc7bf6-50a0-4aff-a5d3-46fa23629a73"
          @click="sendIconValue('곡류')"
        />
        <span>곡류</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fseaweed.png?alt=media&token=da9789d1-3d10-4cb1-8555-8c8102f6e71f"
          @click="sendIconValue('해조류')"
        />
        <span>해조류</span>
      </div>
       <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-fish-135677.png?alt=media&token=4abb2c5a-1578-464a-9f79-0d18d8bcb542"
          @click="sendIconValue('어패류')"
        />
        <span>어패류</span>
      </div>
       <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-cheese-135652.png?alt=media&token=d7de5ff5-8538-43fb-b838-19d3f4dcf184"
          @click="sendIconValue('유제품')"
        />
        <span>유제품</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-mushrooms-135704.png?alt=media&token=e94bc91e-e5ed-4497-a145-0055d32e4bfb"
          @click="sendIconValue('버섯류')"
        />
        <span>버섯류</span>
      </div>
     

     
      
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fcondiment.png?alt=media&token=7ae1cd38-424c-4e72-8722-bd4f7528d9b4"
          @click="sendIconValue('조미료')"
        />
        <span>조미료</span>
      </div>
      <div class="tip-icon">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-can-1546578.png?alt=media&token=b42254aa-0c51-418b-bb9b-3a2aa8b71b55"
          @click="sendIconValue('가공식품')"
        />
        <span>가공식품</span>
      </div>
    </div>
    
<div class="search-box tip"  v-if="tipsData.length > 0">
        <input
          type="text"
          v-model="searchWord"
          class="searchBox tip"
          placeholder="식재료를 검색해보세요"
          @keypress.enter.prevent="searchIngredients"
        />
        <unicon
          name="search"
          @click="searchIngredients"
          class="search-icon"
          width="20px"
          height="20px"
        />
      </div>


  <!-- 동적으로 박스십 div생성-->
    <div class="tip-content">
      <div v-for="(item, index) in tipsData" :key="index" class="box10"
      :ref="'tipItem' + index">
        <div class="info-imgbox-tag">
          <img :src="item.url" alt="사진이 들어갑니다." />
        </div>
        <div class="tip-ing-container">
          <div class="tip-titlebox">
          {{ item.name }}   
          </div>
          <div class="contentbox tip">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return{
      tipsData:[],
      imgUrl:"",
    }
  },
  created(){
    window.scrollTo(0, 0);
  },
  methods: {
    async sendIconValue(value) {
      //console.log(value);
      try {
        const response = await axios.get("/api/information/tip", {
          params: {
            selectIcon: value,
          },
        });
        //console.log(response.data);
        this.tipsData = response.data;
       // console.log(this.tipsData);
      } catch (error) {
        console.log(error);
      }
    },
     searchIngredients() {
    const searchWordLower = this.searchWord.toLowerCase(); // 검색어를 소문자로 변환
    let foundIndex = -1;
    
    // 검색어와 일치하는 아이템을 찾음
    this.tipsData.forEach((item, index) => {
      if (item.name.toLowerCase().includes(searchWordLower)) {
        foundIndex = index;
      }
    });

    // 찾은 아이템으로 스크롤 이동
    if (foundIndex !== -1) {
      this.$refs['tipItem' + foundIndex][0].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/tip.css");
@import url("@/assets/styles/nanumneo.css");
</style>

